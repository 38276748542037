<template>
  <div class="pipeline-chart">
    <div class="pipeline-chart__body">
      <div class="pipeline-chart__column column-left">
        <div class="pipeline-chart__rows list__row">
          <div
            v-for="(item, key) in chartData.datasets"
            :key="key"
            class="item__row"
          >
            <transition-group appear appear-class="bar-fill-start" tag="div">
              <div
                class="item"
                key="fill"
                :style="`
                  width: ${getPercent(item.duration, chartData.total_duration)}%;
                  left: ${getMargin(key)}%;
                  border-color: ${item.color}
                `"
              >
                <span class="item__value" v-if="item.duration > 0">{{ item.duration }} д.</span>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <div class="pipeline-chart__column column-right">
        <div class="pipeline-chart__rows list__row">
          <div
            v-for="(item, key) in chartData.datasets"
            :key="key"
            class="item__row"
          >
            <div class="item__chart">
              <transition-group appear appear-class="bar-fill-start" tag="div">
                <span class="item__percent" key="fill-percent">{{ getPercent(item.deals_count, chartData.total_deals) }}%</span>
                <div
                  class="item__line"
                  key="fill"
                  :style="`
                    background: ${item.color};
                    width: calc(${getPercent(item.deals_count, chartData.total_deals)}% + 10px)
                  `"
                ></div>
              </transition-group>
            </div>
            <div class="item__info">
              <div class="item__title" :title="item.stage_name">{{ item.stage_name }}</div>
              <div class="item__value">{{ item.deals_count }} сделок <span class="currency">{{ item.deals_sum | money }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pipeline-chart__footer">
      <SpriteIcon icon="conversion" class="lg mr-10" style="width: 40px; height: 40px;" /><span class="rx-title font-weight-bold">{{ chartData.conversion }}%</span><span class="rx-text"> - Конверсия</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PipelineChart',
  props: {
    chartData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getPercent (count, total) {
      return total && count ? Math.floor(count / total * 100) : 0
    },
    getMargin (key = 0) {
      let result = 0

      this.chartData.datasets.forEach((el, i) => {
        if (i < key) {
          result += this.getPercent(el.duration, this.chartData.total_duration)
        }
      })

      return result
    }
  }
}
</script>

<style>
.pipeline-chart {
  padding-top: 40px;
}
.pipeline-chart__body {
  display: flex;
}
.pipeline-chart__footer {
  border-top: 1px solid var(--border-color);
  margin-top: 40px;
  padding-top: 40px;
}
.pipeline-chart__column {
  width: 50%;
}
.column-left .item__row {
  padding-right: 30px;
}
.column-left .item {
  height: 30px;
  border-bottom: 5px solid #C8EEFF;
  text-align: right;
  position: relative;
  top: 1px;
  transition: all 1s ease-in-out;
  opacity: 0;
}
.column-left .item::after {
  content: '';
  width: 2px;
  height: 60px;
  background: var(--border-color);
  position: absolute;
  top: calc(100% + 4.5px);
  right: 0;
}
.column-left .item__value {
  position: absolute;
  right: 0;
  bottom: 5px;
  min-width: 40px;
}
.column-left .item__row:last-child .item::after {
  display: none;
}
.column-left .item__row, .column-right .item__chart {
  border-bottom: 1px dashed #B9C3D8;
}
.column-right .item__row {
  display: flex;
}
.column-left .item__row:not(:last-child), .column-right .item__row:not(:last-child) {
  margin-bottom: 30px;
}
.column-right .item__chart {
  width: 60%;
}
.column-right .item__chart > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: 1px;
}
.column-right .item__chart .item__percent {
  padding-right: 5px;
  color: var(--title-color);
  font-weight: 600;
}
.column-right .item__chart .item__line {
  height: 30px;
  -webkit-clip-path: polygon(0 0, 10px 100%, 100% 100%, 100% 0);
  clip-path: polygon(0 0, 10px 100%, 100% 100%, 100% 0);
  transition: width 1s ease-in-out;
}
.column-right .item__info {
  padding-left: 20px;
  width: 40%;
}
.column-right .item__title {
  color: var(--title-color);
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.column-right .item__value {
  color: var(--text-color);
  white-space: nowrap;
  line-height: 1;
}
.bar-fill-start {
  width: 0 !important;
}
</style>
