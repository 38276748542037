<template>
  <div class="dashboard">
    <TopPanel v-if="can('sale_plan') || can('sale_settings')" pageSwitcher />
    <DashboardFilter
      @update="fetchData($event)"
    />
    <Block title="">
      <template slot="header">
        <div class="block-header border-bottom-0">
          <div class="block-title">Статистика продаж</div>
        </div>
        <Loader v-if="isLoading" />
        <b-tabs v-else>
          <b-tab title="Запланировано" active>
            <LineChart v-if="totalSalesPlan.data.length" :chart-data="{labels: labels.value, datasets: [totalSalesPlan]}" class="pt-5" />
          </b-tab>
          <b-tab title="Фактические поступления" lazy>
            <LineChart v-if="totalSales.data.length" :chart-data="{labels: labels.value, datasets: [totalSales]}" class="pt-5" />
          </b-tab>
          <b-tab title="Сравнение" lazy>
            <LineChart v-if="totalSalesPlan.data.length && totalSales.data.length" :chart-data="{labels: labels.value, datasets: [totalSalesPlan, totalSales]}" class="pt-5" />
          </b-tab>
        </b-tabs>
      </template>
    </Block>
    <Block title="Динамика продаж">
      <Loader v-if="isLoading" />
      <LineChart v-else-if="totalSalesStat.data.length" :chart-data="{labels: labels.value, datasets: [totalSalesStat]}" class="pt-5" />
    </Block>
    <Block title="Средний чек">
      <Loader v-if="isLoading" />
      <LineChart v-else-if="avgCheck.data.length" :chart-data="{labels: labels.value, datasets: [avgCheck]}" class="pt-5" />
    </Block>
    <Block title="Длительность сделки">
      <Loader v-if="isLoading" />
      <LineChart v-else-if="avgDuration.data.length" :chart-data="{labels: labels.value, datasets: [avgDuration]}" class="pt-5" />
    </Block>
    <Block v-if="showDiscountPercentChart" title="Размер скидок">
      <Loader v-if="isLoading" />
      <LineChart v-else-if="discountPercent.data.length" :chart-data="{labels: labels.value, datasets: [discountPercent]}" class="pt-5" />
    </Block>
    <Block>
      <template slot="header">
        <div class="block-header border-bottom-0">
          <div class="block-title">Сделки</div>
        </div>
        <Loader v-if="isLoading" />
        <b-tabs v-else>
          <b-tab active>
            <template #title>
              <span class="dot-icon green"></span>Успешные
            </template>
            <LineChart v-if="successDealsCount.data.length" :chart-data="{labels: labels.value, datasets: [successDealsCount]}" class="pt-5" />
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span class="dot-icon red"></span>Проваленные
            </template>
            <LineChart v-if="failedDealsCount.data.length" :chart-data="{labels: labels.value, datasets: [failedDealsCount]}" class="pt-5" />
          </b-tab>
          <b-tab title="Сравнение" lazy>
            <LineChart v-if="successDealsCount.data.length && failedDealsCount.data.length" :chart-data="{labels: labels.value, datasets: [successDealsCount, failedDealsCount]}" class="pt-5" />
          </b-tab>
        </b-tabs>
      </template>
    </Block>
    <Block v-if="showPipelineChart" title="Воронка продаж">
      <Loader v-if="isLoading" />
      <PipelineChart v-else-if="pipelineSales.datasets && pipelineSales.datasets.length" :chart-data="pipelineSales" />
      <div v-else class="pt-25">Недостаточно данных для построения графика</div>
    </Block>
  </div>
</template>

<script>
import Vue from 'vue'
import DashboardFilter from '@/components/sale/DashboardFilter'
import LineChart from '@/components/ui/chart/LineChart'
import PipelineChart from '@/components/ui/chart/PipelineChart'
import TopPanel from '@/components/sale/TopPanel'
import CanMixin from '@/mixins/CanMixin.js'
import { mapState } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    DashboardFilter,
    LineChart,
    PipelineChart,
    TopPanel
  },
  mixins: [CanMixin],
  data () {
    return {
      isLoading: true,
      labels: {
        value: []
      },
      totalSales: {
        data: [],
        color: '#55B390',
        gradient: [85, 179, 144],
        label: 'Факт'
      },
      totalSalesPlan: {
        data: [],
        color: '#5297FF',
        gradient: [82, 151, 255],
        label: 'План'
      },
      totalSalesStat: {
        data: [],
        color: '#55B390',
        gradient: [85, 179, 144]
      },
      avgCheck: {
        data: [],
        color: '#FFC73F',
        gradient: [250, 204, 93]
      },
      avgDuration: {
        data: [],
        color: '#5D5FEF',
        gradient: [93, 95, 239]
      },
      discountPercent: {
        data: [],
        color: '#FF685F',
        gradient: [255, 104, 95]
      },
      successDealsCount: {
        data: [],
        color: '#55B390',
        gradient: [85, 179, 144],
        label: 'Успешные'
      },
      failedDealsCount: {
        data: [],
        color: '#FF685F',
        gradient: [255, 104, 95],
        label: 'Проваленные'
      },
      pipelineSales: {},
      showPipelineChart: false,
      showDiscountPercentChart: false
    }
  },
  computed: {
    ...mapState('sale/settings', ['deal_sum'])
  },
  async created () {
    await this.$store.dispatch('sale/settings/fetchSettings')

    if (['product', 'mixed'].indexOf(this.deal_sum) !== -1) {
      this.showDiscountPercentChart = true
    }
  },
  methods: {
    async fetchData (value) {
      this.isLoading = true
      let queryUrl = `sale/report/dashboard?startDate=${value.startDate}&endDate=${value.endDate}&group=${value.group}`
      if (value.user) queryUrl += `&user=${value.user}`

      if (value.pipeline !== 'all') {
        queryUrl += `&pipeline=${value.pipeline}`
        this.showPipelineChart = true
      } else {
        this.showPipelineChart = false
      }
      const result = await this.$api.query(queryUrl)

      this.updateChartData(result)

      this.isLoading = false
    },
    updateChartData (result) {
      if (result.labels) {
        Vue.set(this.labels, 'value', result.labels)
      }
      if (result.total_sales) {
        Vue.set(this.totalSales, 'data', result.total_sales)
      }
      if (result.total_sales_plan) {
        Vue.set(this.totalSalesPlan, 'data', result.total_sales_plan)
      }
      if (result.total_sales_stat) {
        Vue.set(this.totalSalesStat, 'data', result.total_sales_stat)
      }
      if (result.avg_check) {
        Vue.set(this.avgCheck, 'data', result.avg_check)
      }
      if (result.avg_duration) {
        Vue.set(this.avgDuration, 'data', result.avg_duration)
      }
      if (result.discount_percent) {
        Vue.set(this.discountPercent, 'data', result.discount_percent)
      }
      if (result.success_deals_count) {
        Vue.set(this.successDealsCount, 'data', result.success_deals_count)
      }
      if (result.failed_deals_count) {
        Vue.set(this.failedDealsCount, 'data', result.failed_deals_count)
      }
      this.pipelineSales = result.pipeline_sales ?? []
    }
  }
}
</script>

<style>
.dashboard .nav-tabs .nav-link {
  padding-bottom: 14px;
}
</style>
