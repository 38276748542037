<script>
import { Line, mixins } from 'vue-chartjs'
import Chart from 'chart.js'
import crosshairPlugin from 'chartjs-plugin-crosshair'
import MoneyFilter from '@/filters/money.filter'

Chart.plugins.register(crosshairPlugin)
const { reactiveProp } = mixins

// add custom position for tooltip
Chart.Tooltip.positioners.top = function (items) {
  const pos = Chart.Tooltip.positioners.average(items)

  if (pos === false) {
    return false
  }

  const chart = this._chart

  return {
    x: pos.x,
    y: chart.chartArea.top,
    xAlign: 'center',
    yAlign: 'top'
  }
}
Chart.defaults.global.defaultFontFamily = 'Open Sans'
Chart.defaults.scale.ticks.min = 0

export default {
  name: 'LineChart',
  extends: Line,
  mixins: [reactiveProp],
  data () {
    return {
      options: {
        legend: false,
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          intersect: false
        },
        scales: {
          yAxes: [{
            gridLines: {
              color: 'rgba(185, 195, 216, 0.6)',
              borderDash: [2, 2],
              drawBorder: false,
              zeroLineWidth: 0,
              offsetGridLines: true
            },
            ticks: {
              fontColor: '#808BAC',
              fontSize: 13,
              padding: 20,
              callback: function (value, index, values) {
                return /\d+/.test(value) ? MoneyFilter(value) : value
              }
            }
          }],
          xAxes: [{
            gridLines: {
              color: 'rgba(185, 195, 216, 0.6)',
              borderDash: [2, 2],
              drawBorder: false,
              zeroLineWidth: 1,
              zeroLineBorderDash: [2, 2]
            },
            ticks: {
              fontColor: '#808BAC',
              fontSize: 13,
              padding: 20
            }
          }]
        },
        plugins: {
          crosshair: { // vertical line with tooltip when hover
            line: {
              color: '#1F2641',
              width: 1
            },
            snap: {
              enabled: true
            },
            zoom: {
              enabled: false
            },
            sync: {
              enabled: false
            }
          }
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          position: 'top',
          backgroundColor: 'transparent',
          bodyFontColor: '#1F2641',
          bodyFontSize: 12,
          bodyFontStyle: 600,
          enabled: false,
          custom: function (tooltipModel) {
            let tooltipEl = document.getElementById('chartjs-tooltip')

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div')
              tooltipEl.id = 'chartjs-tooltip'
              document.body.appendChild(tooltipEl)
            }

            function getBody (bodyItem) {
              return bodyItem.lines
            }

            let innerHtml = ''
            if (tooltipModel.body) {
              const bodyLines = tooltipModel.body.map(getBody)

              bodyLines.forEach(line => {
                innerHtml += '<span>' + (/^\d+$/.test(line) ? MoneyFilter(line) : line) + '</span><br>'
              })
            }

            tooltipEl.innerHTML = innerHtml

            const position = this._chart.canvas.getBoundingClientRect()

            tooltipEl.style.opacity = 1
            tooltipEl.style.position = 'absolute'
            tooltipEl.style.left = (position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth / 2) + 'px'
            tooltipEl.style.top = (position.top + window.pageYOffset - tooltipModel.caretY - 10) + 'px'
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
            tooltipEl.style.pointerEvents = 'none'
          }
        }
      }
    }
  },
  mounted () {
    const defaultOptions = {
      tension: 0,
      borderWidth: 1,
      pointBorderWidth: 1,
      pointRadius: 5,
      pointBackgroundColor: '#ffffff'
    }

    // fill datasets fields
    this.chartData.datasets.forEach((val, index) => {
      // add gradient if need
      if (val.gradient) {
        const grad = this.chartData.datasets[index].gradient
        const gradient = this.$refs.canvas
          .getContext('2d')
          .createLinearGradient(0, 0, 0, 450)

        gradient.addColorStop(0, 'rgba(' + grad[0] + ', ' + grad[1] + ', ' + grad[2] + ', 0.5)')
        gradient.addColorStop(0.1, 'rgba(' + grad[0] + ', ' + grad[1] + ', ' + grad[2] + ', 0.25)')
        gradient.addColorStop(0.7, 'rgba(' + grad[0] + ', ' + grad[1] + ', ' + grad[2] + ', 0)')

        this.chartData.datasets[index].backgroundColor = gradient
        delete this.chartData.datasets[index].gradient
      }

      // add color
      if (val.color) {
        this.chartData.datasets[index].borderColor = val.color
        this.chartData.datasets[index].pointBorderColor = val.color
        delete this.chartData.datasets[index].color
      }

      // add default options
      for (const option in defaultOptions) {
        if (!(option in val)) {
          this.chartData.datasets[index][option] = defaultOptions[option]
        }
      }
    })

    this.renderChart(this.chartData, this.options)
  }
}
</script>
