<template>
  <Block class="dashboard-filter__wrapper pb-30">
    <div class="dashboard-filter">
      <div class="d-flex align-items-center">
        <b-dropdown
          :text="selectedEntity.text"
        >
          <b-dropdown-item
            v-for="item in entities"
            :key="item.value"
            @click="updateEntity(item.value)"
          >
            {{ item.text }}
          </b-dropdown-item>
        </b-dropdown>

        <EntitySelect2
          v-if="selectedEntity.type === 'user'"
          style="width: 300px"
          class="ml-15"
          url="user"
          v-model="selectedEntity.value"
          @input="onInput"
          label="name"
          placeholder="Выберите сотрудника"
          state
        />
      </div>
      <div>
        <b-dropdown
          :text="selectedPipeline.text"
          class="mr-15"
        >
          <b-dropdown-item
            v-for="item in pipelines"
            :key="item.value"
            @click="updatePipeline(item)"
          >
            {{ item.text }}
          </b-dropdown-item>
        </b-dropdown>

        <date-range-picker
          ref="rangepicker"
          v-model="dateRange"
          :locale-data="dateRangePickerOptions.localeData"
          :ranges="dateRangePickerOptions.ranges"
          show-dropdowns
          autoApply
          :always-show-calendars="false"
          control-container-class="dashboard-filter__item btn dropdown-toggle"
          opens="left"
          @update="updateRangeFilter($event)"
        >
          <span slot="input">{{ rangeInfo.title }}</span>
        </date-range-picker>
      </div>
    </div>
  </Block>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'

export default {
  name: 'DashboardFilter',
  components: {
    DateRangePicker
  },
  computed: {
    dateRangePickerOptions () {
      const date = moment()
      const prevMonthDate = moment()
      const prevYearDate = moment()

      return {
        localeData: {
          format: 'dd.mm.yyyy',
          applyLabel: 'Применить',
          cancelLabel: 'Отменить',
          customRangeLabel: 'Выбрать период',
          daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
          monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
          firstDay: 1
        },
        ranges: {
          'Текущий месяц': [date.startOf('month').toDate(), date.endOf('month').toDate(), 'day'],
          'Прошлый месяц': [prevMonthDate.subtract(1, 'months').startOf('month').toDate(), prevMonthDate.endOf('month').toDate(), 'day'],
          'Текущий год': [date.startOf('year').toDate(), date.endOf('year').toDate(), 'month'],
          'Прошлый год': [prevYearDate.subtract(1, 'years').startOf('year').toDate(), prevYearDate.endOf('year').toDate(), 'month']
        }
      }
    },
    rangeInfo () {
      let title = moment(this.dateRange.startDate).format('DD.MM.YYYY') + ' - ' + moment(this.dateRange.endDate).format('DD.MM.YYYY')
      let group = 'day'

      for (const key in this.dateRangePickerOptions.ranges) {
        const el = this.dateRangePickerOptions.ranges[key]
        if (moment(el[0]).unix() === moment(this.dateRange.startDate).unix() && moment(el[1]).unix() === moment(this.dateRange.endDate).unix()) {
          title = key
          group = el[2]
        }
      }

      return {
        title: title,
        group: group
      }
    }
  },
  data () {
    return {
      dateRange: {
        startDate: '',
        endDate: ''
      },
      selectedEntity: {
        type: '',
        value: '',
        text: ''
      },
      entities: [
        {
          text: 'Вся компания',
          value: 'company'
        },
        {
          text: 'Сотрудник',
          value: 'user'
        }
      ],
      selectedPipeline: {
        value: '',
        text: ''
      },
      pipelines: [
        {
          text: 'Все направления',
          value: 'all'
        }
      ]
    }
  },
  async created () {
    this.selectedEntity.type = this.entities[0].value
    this.selectedEntity.text = this.entities[0].text

    const date = moment()
    this.dateRange.startDate = date.startOf('month').toDate()
    this.dateRange.endDate = date.endOf('month').toDate()

    this.selectedPipeline.value = this.pipelines[0].value
    this.selectedPipeline.text = this.pipelines[0].text

    await this.$api.query('sale/pipeline').then(response => {
      if (response && response.length > 0) {
        const pipelines = response.map(pipeline => {
          return {
            text: pipeline.title,
            value: pipeline.id
          }
        })

        for (const key in pipelines) {
          const pipeline = pipelines[key]

          this.pipelines.push(pipeline)
        }
      }
    })

    this.emitChanges()
  },
  methods: {
    updateEntity (value) {
      this.selectedEntity.type = this.entities.find(el => el.value === value).value
      this.selectedEntity.text = this.entities.find(el => el.value === value).text

      if (this.selectedEntity.type === 'company') {
        this.selectedEntity.value = ''
        this.emitChanges()
      }
    },
    updateRangeFilter (value) {
      this.emitChanges()
    },
    updatePipeline (value) {
      this.selectedPipeline = value
      this.emitChanges()
    },
    emitChanges () {
      const params = {
        entity: this.selectedEntity.type,
        startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
        group: this.rangeInfo.group,
        pipeline: this.selectedPipeline.value
      }
      if (params.entity === 'user') {
        params.user = this.selectedEntity.value ? this.selectedEntity.value : null
      }
      this.$emit('update', params)
    },
    onInput (value) {
      if (value) {
        this.emitChanges()
      }
    }
  }
}
</script>

<style>
.dashboard-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-filter__item {
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
}
.dashboard-filter .dropdown-toggle {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  font-size: 13px;
}
</style>
